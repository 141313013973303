.dno{
    background-color: #0d1724;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    flex-wrap: wrap;
    z-index: 10;
    
  }
  .dnoSpan{
    color: white;
  width: 110px;
  margin: 0 10%;
  }
.dnoPC{
  background-color: #0d1724;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  flex-wrap: wrap;
  z-index: 10;
  
}
.dnoDiv{
color: white;
margin: 10px 10px 20px 80px;
}
.laptopFooter{
  font-size: smaller;
}
@media only screen and (min-width: 800px) and (max-width: 1600px) {
  .laptopFooter{
    font-size: medium;
  }
}

footer span{
  display: inline-flex;
}
footer span:nth-child(even){
  overflow: hidden;
  transition:ease-in-out 0.5s;
  letter-spacing: -1em;
}

footer:hover span:nth-child(even){
letter-spacing: 0;
}

