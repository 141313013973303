

.image-container {
    display: inline-block;
    position: relative;
    width: 250px;
    height: 250px;
    margin: 40px;
    background-color: rgb(175, 183, 200);
    background-image: radial-gradient(circle, rgb(204, 219, 251) 29%, rgb(164, 185, 206) 71%);
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgba(66, 68, 90, 1);
    padding: 10px;
  
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
    cursor: pointer;
}

.overlayZ {
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 80%;
    height: 28%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center;
    font-size: 21px;
    border-radius: 5px;
    font-weight: bold;
    text-shadow: 
    -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000;

}

@keyframes moveUp {
    from {
        bottom: 0px;

    }
    to {
        bottom: 40%; /* Move up completely out of the container */
    }
}

.image-container:hover .overlayZ {
    opacity: 1;
    cursor: pointer;
    animation: moveUp 0.5s ease-in-out forwards;
   
}
.abtUs{
    padding-left: 30px;
     text-align: center;
     font-family: "Roboto Regular","Helvetica Neue","Helvetica","Arial","sans-serif";
     font-size: 26px;
     line-height: 31px;
     letter-spacing: 1.38px;
     font-weight: normal;
     color: #27292b;
   }