.why {
    background-color: rgba(180, 197, 238, 1);
    background-image: linear-gradient(180deg, rgba(180, 197, 238, 0.7) 47%, rgb(96, 155, 198) 100%);
    width: fit-content;
    padding: 10px;
    -webkit-box-shadow: 5px 5px 13px -8px rgba(69, 73, 87, 1);
-moz-box-shadow: 5px 5px 13px -8px rgba(69, 73, 87, 1);
box-shadow: 5px 5px 13px -8px rgba(69, 73, 87, 1);
border-radius: 5px;

}
@media only screen and (min-width: 1000px) and (max-width: 1576px) {
    .why{
font-size: smaller;
    }
  }
@media only screen and (min-width: 1577px) and (max-width: 2000px) {

    .why{
font-size: large;
    }
  }

.whyIcons{
object-fit: contain;
width: 80px;
padding: 15px 0px;

}

.inlB{
    display: inline-block;
    padding: 5px 15px;
    vertical-align: top;
}
.tristaPedeset{
    width: 250px;
}
.wcp {
    text-align: center;
    font-size: large;
width: 100%;
  padding: 0 40px;

}
.hovPurp{
    color: #23559b;
    text-decoration: none;
}
.hovPurp:hover{
    color: #4a2464;
}
.gradHome{
    background-image: linear-gradient(352deg, rgb(131, 166, 203) 42%, rgb(0, 35, 83) 100%)
}
.slick-initialized .slick-slide {
    display: block ruby !important;
  }
  .slick-arrow{
    background-color: #23559a !important;
    border-radius: 20px;
  }