.paddin {
    padding-left: 20px;
}
.paddinMenu{
    padding-left: 60px;
}
.container {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center horizontally */
    padding: 15px;
    margin: 0 auto;
    width: 80%;
}
.my-div {
    display: flex;

    padding-left: 10px;
    align-items: center;
    justify-content: center; 
    color: #24569b;
    border-radius: 10px;
    box-shadow: 0px 0px 9px -1px rgba(85, 90, 130, 1);
    background-color: rgba(213, 224, 254, 1);
    background-image: radial-gradient(circle, rgb(212, 223, 255) 31%, rgb(201, 221, 240) 100%);
}
.my-div:hover{
    box-shadow: 1px 0px 20px 1px rgba(89, 103, 149, 1);
    transition: ease-in-out 0.4s;
}
.upit {
    font-size: x-large;
    color: #24569b;
    padding: 50px;
    border: 4.5px solid #24569b;
    border-radius: 60px;
} 
input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #24569b;
    border-radius: 20px; /* Rounded corners */
    outline: none;
    margin-bottom: 10px;
}
.buttonSubm {
    background-color: #346cb6;
    border: none;
    color: white;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    float: right;
    font-size: 14px;
    margin: 4px 2px;
    margin-top: 12px;
    border-radius: 15px; /* Adjust the value as needed */
    cursor: pointer;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.buttonSubm:hover{
  background-color: #0555bb;

}

@media only screen and (min-width: 100px) and (max-width: 800px) {
    .razmakContact{
 margin-bottom: 40px;
    }
  }
  @media only screen and (min-width: 800px) and (max-width: 1500px) {
    .razmakContact{
 margin: 0px 50px;
    }
  }
  @media only screen and (min-width: 1501px) {
    .razmakContact{
 margin: 0px 50px;
    }
  }

  @media only screen and (min-width: 100px) and (max-width: 1200px) {
    .hPadd{
padding: 1px;
    }
  }
  @media only screen and (min-width: 1201px) and (max-width: 1500px) {
    .hPadd{
 padding: 10px;
    }
  }
  @media only screen and (min-width: 1501px) {
    .hPadd{
padding: 15px;
    }
  }