/* Add this to your CSS */
.App {
    display:flex ;
    flex-direction: column;
    width:100%;
    background-color: #d8dce8;
  }
  html {
    scroll-behavior: smooth;
}
  .headDiv{
    display: flex;
    flex-wrap: wrap;
    border-radius: 0px 0px 10px 10px;
  box-shadow: 0 6px 16px 0 rgba(25,25,25,.06);
  background: #ffffff;
  }
  .logoDiv {
    display: flex;
    margin: 5px; /* Adjust margin as needed */
    padding-left: 20px;
    align-items: end;


}
.menuDiv {
    display: flex;
    flex: 1;
    margin: 5px;
    padding-left: 20px;
    align-items: center;
    justify-content: end;

}

.underline-on-hover {
  text-decoration: none; 
  transition: text-decoration 0.3s; 
}
.languageButton {
  border: none;
  background: none !important; /* Remove button background */
  padding: 0; /* Remove padding */
  cursor: pointer; /* Change cursor to pointer on hover */
  opacity: 1; /* Default opacity */
  transition: opacity 0.3s; /* Add transition for smooth change */
  transition: 0,5s;
}

.languageButton:hover {
  transform: scale(1.1); 
  border-radius: 50%;
  transition: 0,5s;
  opacity: 1;
}

.activeLanguageButton {
  opacity: 1; 
  border-radius: 50%;
}
.activeLanguageButton img{
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.5); 
  border-radius: 50%;
}
.languageButton:not(.activeLanguageButton) {
  opacity: 0.3; /* Dim inactive buttons to 30% opacity */
}
.dividerLang {
  width: 2px;
  height: 51px;
  background-color: #24589f;
  margin: 0 10px;
}
.spanLang{
  border: 3px solid #24589f;
  border-radius: 15px;
  display: flex;
  margin-left: 1px;
  margin-right: 32px;
  padding-left: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 12px;
}
.spanLangM{
  display: flex;
  margin-left: 20px;
  margin-right: 32px;
  padding-left: 12px;
  padding-top: px;
  padding-bottom: 17px;
  padding-right: 12px;
}

.spanLangPhone{
  border: 3px solid #b3d7d2;
  border-radius: 15px;
  display: flex;
  margin-left: 1px;
  margin-right: 32px;
  padding-left: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 12px;
}

.meniIcon{
  width: 35px;
  
}
.invis{
  background: none;
  border: none;
}
.lilPad{
  padding-left:10px;
  padding-right: 5px;
}
 .overlay{
  position: absolute;
  top: 25%;
  left: 25%;
  transform: translate(-50%, -50%);
  color: white;
  background: none; 
  padding: 10px;
} 
.glowImg{
  display: inline-block;
  width: 47%;
  margin: 5px;
  height: 185px;
  background: none;
  border-radius: 15px;
  box-shadow: 0 0 1px 1px #5a9e9e;
  transition: transform 0.3s ease;
}
.glowImgPC:hover{
  transform: scale(1.02);
}
.glowImgPC{
  display: inline-block;
  width: 20%;
  height: 350px;
  padding: 10px;
  margin: 30px;
  background: none;
  border-radius: 15px;
  box-shadow: 0 0 10px 1px #cbcbf9 
}

.back-to-top{
position: fixed;
bottom: 50px;
right: 5px;
background: none;
border: none;
opacity: 0.5;
z-index: 3;
cursor: pointer;
color: #22559c;
}

.back-to-top:hover {
  opacity: 1;
  color: rgb(94, 139, 236);
}