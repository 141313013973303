.bodyS {
  margin: 0 auto;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
  width: 80%;
}
.contentS {
  text-align: center;
  margin-bottom: 30px;
}
.headerS {
  font-size: 42px;
}
.imgS {
  
width: 250px;
height: 250px;
margin: 20px;
background-color: rgb(175, 183, 200);
background-image: radial-gradient(circle, rgb(204, 219, 251) 29%, rgb(164, 185, 206) 71%);
border-radius: 5px;
box-shadow: 0px 0px 4px 0px rgba(66, 68, 90, 1);
padding: 10px;
cursor: pointer;
}
.overlayS{
  position: sticky;
  bottom: 22.5%;
  background: rgba(0, 0, 0, 0.3);
  width: 250px;
  height: 26%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: opacity 0.3s ease;
  text-align: center;
  font-size: 21px;
  border-radius: 5px;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  margin: 20px;
  padding: 10px;
}
@media (max-width: 390px) {
  .imgS {
    width: 180px;
    height: 180px;
  }
  .overlayS {
    width: 180px;
    height: 30px;
    bottom: 29%;
  }
}

@media (min-width: 391px) and (max-width: 499px) {
  .imgS {
    width: 200px;
    height: 200px;
  }
  .overlayS {
    width: 200px;
    bottom: 26%;
  }
}
@media (min-width: 500px) and (max-width: 699px) {
  .imgS {
    width: 300px;
    height: 300px;
  }
  .overlayS {
    width: 300px;
    bottom: 20%;
  }
}

@media (min-width: 700px) and (max-width: 919px) {
  .imgS {
    width: 400px;
    height: 400px;
  }
  .overlayS {
    width: 400px;
    height: 50px;
    bottom: 20%;
  }
}
@media (min-width: 1107px) and (max-width: 1171px) {
  .imgS {
    width: 310px;
height: 310px;
  }
  .overlayS {
    width: 310px;
    height: 40px;
    bottom: 22%;
  }
}
@media (min-width: 1172px) and (max-width: 1289px) {
  .imgS {
    width: 330px;
height: 330px;
  }
  .overlayS {
    width: 330px;
    height: 45px;
    bottom: 22%;
  }
}
@media (min-width: 1290px) and (max-width: 1400px) {
  .imgS {
    width: 370px;
height: 370px;
  }
  .overlayS {
    width: 370px;
    height: 50px;
    bottom: 21%;
  }
}


.containerS {
  margin: 0 auto;
  width: 100%;
  border-color: white;
}
.titleS {
  margin-left: 50px;
  width: 70%;
  font-size: 30px;
  padding: 20px;
  border-radius: 5px;
  font-size: 33px;
}
.descriptionS {
  margin: 20px auto;
  width: 80%;
  font-size: 25px;
  margin: 20px auto;
}

